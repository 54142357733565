<template>
    <div>
        <van-field readonly clickable name="picker" :value="unitMap[value]" :label="itemData.cnFieldCaption" @click="showPicker = true"/>
        <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar :columns="option" @confirm="onConfirm" @cancel="showPicker = false" :value-key="valueKey"/>
        </van-popup>
    </div>
</template>
<script>
export default {
    name: 'sysBoxItem',
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: 'otNew'
        },
        sysBoxValue: {
            type: Array,
            default: () => {
                return []
            }
        },
        itemData: {
            type: Object,
            default: () => {
                return {}
            }
        },
        valueKey: {
            type: String,
            default: ''
        },
        option: {
            type: Array,
            default: () => {
                return []
            }
        },
        value: {
            type: String,
            default: ''
        },
        unitMap: {
           type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            showPicker: false
        }
    },
    methods: {
        onConfirm(value) {
            this.$emit('changeInput', value)
            this.showPicker = false
        }
    }
}
</script>

<style lang="less" scoped>
</style>
