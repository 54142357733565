<template>
    <div class="newAndEdit">
        <div class="loading-box" v-if="loading"><van-loading type="spinner" /></div>
        <template v-else>
            <business-card v-if="showBusinessCard" @cardLoading="cardLoading" @submitAddFrom="submitAddFrom" @getCustData="getCustData"></business-card>
            <span v-show="!isCardLoading">
                <baseMsg :sysBoxValue="sysBoxValue" :editSet="editSet" :optCode="optCode" :moduleCode="moduleCode" ref="baseMsg" :cusBoxValue="cusBoxValue"></baseMsg>
                <baseMsg :sysBoxValue="sysBoxValue" :editSet="customerFields" :optCode="optCode" :moduleCode="moduleCode" ref="custMsg" :cusBoxValue="cusBoxValue"></baseMsg>
                <div v-for="(item,index) in strucId2Data" :key="item.id">
                    <contact :editSet="contactFields" :optCode="optCode" :moduleCode="moduleCode" :index="index" :itemData="item" @deleteContact="deleteContact" @addContact="addContact" ref="contact"></contact>
                </div>
                <goods-list :editSet="goodsFields" :optCode="optCode" :moduleCode="moduleCode" :goodsType="goodsType" ref="goodsList" :classTypeInUseList="classTypeInUseList" @changeCategory="changeCategory" :sysBoxValue="sysBoxValue" :unitList="unitList"></goods-list>
            </span>
        </template>
    </div>
</template>
<script>
import baseMsg from './baseMsg/index.vue'
import titleMixin from '@/mixin/title'
import contact from './contact/index.vue'
import goodsList from './goodsList/index.vue'
import { mapGetters, mapActions } from 'vuex'
import { getUUID } from '@/libs/utils'
import BusinessCard from './BusinessCard/index.vue'
export default {
    name: 'NewAndEdit',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.common.1665806245891') }, // '新增线索'
    computed: {
        ...mapGetters(['sysBoxValue', 'unitList', 'cusBoxValue'])
    },
    data() {
        return {
            loading: true,
            moduleCode: '',
            editSet: [],
            optCode: 'otNew',
            isEditShowSet: [],
            customerFields: [],
            ownerFields: [],
            filingFields: [],
            isEditShowSetcustomer: [],
            customerArr: [],
            contactFields: [],
            strucId2Data: [],
            contactObj: {},
            goodsType: {},
            goodsFields: [],
            classTypeInUseList: [],
            showBusinessCard: false,
            isCardLoading: false
        }
    },
    mounted() {
        if (this.sysBoxValue && this.sysBoxValue.length == 0) {
            this.setSysBoxValue()
        }
        if (this.unitList && this.unitList.length == 0) {
            this.setUnitList()
        }
        // viewName,
        let {moduleCode, id} = this.$route.params
        this.moduleCode = moduleCode
        this.setMenu()
        this.getAddData()
        if (id === 'businessCard') {
            this.showBusinessCard = true
        }
    },
    methods: {
        // 个人信息存起来
        ...mapActions([
            'setSysBoxValue', 'setUnitList'
        ]),
        getCustData(data) {
            this.$refs.custMsg.update(data)
            this.$refs.contact[0].update(data)
        },
        cardLoading(loading) {
            this.isCardLoading = loading
        },
        changeCategory(value) {
            this.goodsType = value
        },
        deleteContact(index) {
            this.strucId2Data.splice(index, 1)
        },
        addContact() {
            let obj = JSON.parse(JSON.stringify(this.contactObj))
            obj.id = getUUID()
            this.strucId2Data.push(obj)
        },
        setMenu() {
            let rightMenu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.Client.1585301134763')) // '保存'
            rightMenu.onClick(() => {
                this.submitAddFrom()
            })
        },
        submitAddFrom() {
            let submitData = {
                'moduleCode': this.moduleCode,
                'strucId': 1,
                'custRelyType': 1,
                'category': this.goodsType.catgId
            }
            let baseMsgData = this.$refs.baseMsg.submit()
            if (!baseMsgData) {
                this.$toast.fail(this.$t('mx_mobile.common.1665816889350')) // '请完善基本信息！'
                return false
            }
            let customerMsg = this.$refs.custMsg.submit()
            if (!customerMsg) {
                this.$toast.fail(this.$t('mx_mobile.common.1665816953177')) // '请完善客户信息！'
                return false
            }
            let custContacts = []
            this.strucId2Data.forEach((ele, index) => {
                let obj = this.$refs.contact[index].submit()
                let objClone = JSON.parse(JSON.stringify(obj))
                objClone.mailAddress = objClone.mailAddress.filter(s => {
                    return s && s.trim()
                })
                ele.orderNo = index
                custContacts.push(objClone)
            })
            let goodsList = this.$refs.goodsList.submit()
            let data = Object.assign({}, submitData, baseMsgData, customerMsg)
            data.strucId_2 = custContacts
            data.strucId_3 = goodsList
            this.submitAdd(data)
            // console.log(data)
        },
        submitAdd(data1) {
           this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: this.$t('mx_mobile.Client.1585300903213') // 保存中...
            })
           this.getFormKey((formKey, msg) => {
                if (formKey && formKey != '') {
                    this.axios.post(this.Global.config.apiBaseURL + this.Global.api.v2.document_quotation_post, {
                            ...data1,
                            formKey: formKey
                        }).then((res) => {
                            this.$toast.clear()
                            if (res.data.code.toString() == this.Global.config.RES_OK) {
                                this.$toast.success(res.data.msg)
                                this.Global.utils.rightMenu.clearMenu()
                                // this.set_closeYes(false)
                                this.$router.back()
                            } else {
                                this.$toast.fail(res.data.msg)
                            }
                        }, (res) => {
                            this.$toast.clear()
                            this.$toast.fail(
                                this.Global.config.errorTitle
                            )
                        })
                } else {
                   this.$toast.clear()
                   this.$toast.fail(msg)
                }
            })
        },
        async getClassType() {
            let list = []
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.document_product_category
                let res = await this.axios.get(url, {
                    params: {
                        type: 'inUse'
                    }
                })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    list = res.data.data.list
                } else {
                    this.loading = false
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                this.loading = false
                this.$toast.fail(this.Global.config.errorTitle)
            }
            return list
        },
        getAddData() { // 获取数据
            var p1 = new Promise((resolve, reject) => {
                this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.fieldShow_get, {
                    params: {
                        moduleCode: this.moduleCode,
                        type: 'addEditSet'
                    }
                }).then((res) => {
                    if (res.data.code.toString() == this.Global.config.RES_OK) {
                        resolve(res.data.data)
                    } else {
                        this.loading = false
                        this.$toast.fail(res.data.msg)
                    }
                }, (res) => {
                    this.loading = false
                    this.$toast.fail(this.Global.config.errorTitle)
                })
            })
            var p2 = new Promise((resolve, reject) => {
                this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.fieldShow_get, {
                    params: {
                        moduleCode: this.moduleCode,
                        type: 'addEditSet',
                        strucId: 2
                    }
                }).then((res) => {
                    if (res.data.code.toString() == this.Global.config.RES_OK) {
                        let list = res.data.data || []
                        resolve(list)
                    } else {
                        this.loading = false
                        this.$toast.fail(res.data.msg)
                    }
                }, (res) => {
                    this.loading = false
                    this.$toast.fail(this.Global.config.errorTitle)
                })
            })
            var p3 = new Promise((resolve, reject) => {
                this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.fieldShow_get, {
                    params: {
                        moduleCode: this.moduleCode,
                        type: 'addEditSet',
                        strucId: 3
                    }
                }).then((res) => {
                    if (res.data.code.toString() == this.Global.config.RES_OK) {
                        let list = res.data.data || []
                        resolve(list)
                    } else {
                        this.loading = false
                        this.$toast.fail(res.data.msg)
                    }
                }, (res) => {
                    this.loading = false
                    this.$toast.fail(this.Global.config.errorTitle)
                })
            })
            Promise.all([p1, p2, p3, this.getClassType()]).then((results) => {
                this.loading = false
                let editSet1 = results[0]
                this.swidchEditSet(editSet1)
                this.contactFields = this.swidchEditSetChild(results[1]).filter(ele => ele.isEditShow != 2 && ele.fieldName != 'contId')
                this.contactFields.map(ele => {
                    this.contactObj[ele.fieldName] = ''
                })
                let objData = JSON.parse(JSON.stringify(this.contactObj))
                objData.id = getUUID()
                this.strucId2Data.push(objData)// 陌生客户
                this.goodsFields = this.swidchEditSetChild(results[2])
                this.classTypeInUseList = results[3]
                this.classTypeInUseList?.length > 0 && (this.goodsType = this.classTypeInUseList[0])
                // console.log(this.goodsFields, '商品')
            })
        },
        returnGroupName(groupId) {
            switch (groupId) {
                case 1:
                    return this.$t('mx_mobile.Client.1585625326467') // '地区'
                case 3:
                    return this.$t('mx_mobile.Client.1585625334699') // '所属'
                case 4:
                    return this.$t('mx_mobile.Client.1585625343907') // '创建人'
                case 5:
                    return this.$t('mx_mobile.Client.1585625344676') // '修改人'
                default:
                    return ''
            }
        },
        swidchEditSetChild(list) {
            let newLList = []
            let groupFirst = []
            list.forEach((element) => {
                element.controlData = ''
                element.disabled = this.fieldIsEditable(this.optCode, element.editState)
                element.fieldValue = element.inDefaultValue
                if (element.fieldGroup !== 0) { // 判断是否是一个组
                    let isHave = false
                    let thisGroup = ''
                    groupFirst.forEach((item) => { // 判断这个组是否出现过，出现则在groupFirst里做个标记
                        if (item == element.fieldGroup) {
                            isHave = true
                            thisGroup = item // 记住这个组id
                        }
                    })
                    if (!isHave) { // 如果没有出现过新建一个对象放入newList里面
                        let newObj = {
                            fieldGroup: element.fieldGroup,
                            cnFieldCaption: this.returnGroupName(element.fieldGroup),
                            isTop: element.isTop,
                            group: []
                        }
                        newObj.group.push(element)
                        newLList.push(newObj)
                        groupFirst.push(element.fieldGroup)
                    } else { // 如果出现过就找之前创建的对象将自己放入到group数组中
                        newLList.forEach((them) => {
                            if (them.fieldGroup == thisGroup) {
                                them.group.push(element)
                            }
                        })
                    }
                } else {
                    newLList.push(element)
                }
            })
            return newLList
        },
        swidchEditSet(list) {
            this.editSet = []
            let newListBase = list
            let editSet = []
            let fieldCategoryArr = newListBase.filter(item => item.fieldCategory == 4)
            let fieldCategoryObj = {}
            fieldCategoryArr.forEach(ele => {
                let obj = {}
                if (ele.returnFieldList.length > 0) {
                    let arr = ele.returnFieldList.split(';')
                    obj[ele.fieldId] = [...arr]
                }
                Object.assign(fieldCategoryObj, obj)
            })
            let setArrChildFunc = function (num) { // 分离各模块字段num为fieldCategory == 4的fieldId
                let contactSetArr = fieldCategoryObj[num] // fieldId ==40018的是客户联系信息
                let childArr = []
                childArr = newListBase.filter(item => item.fieldId == num)
                newListBase.forEach(ele => {
                    let str = ''
                    if (contactSetArr.length > 0 && contactSetArr[0].split(',').length > 0) {
                        str = contactSetArr[0].split(',')
                    }
                    if (str.includes(ele.fieldId + '')) {
                        childArr.push(ele)
                    }
                })
                return childArr
            }
            let processDataFunc = (list) => { // 处理字段信息，组装主控件字段
                let newLList = []
                let groupFirst = []
                list.forEach((element) => {
                    element.controlData = ''
                    element.disabled = this.fieldIsEditable(this.optCode, element.editState)

                    element.fieldValue = element.inDefaultValue
                    if (element.fieldGroup !== 0) { // 判断是否是一个组
                        let isHave = false
                        let thisGroup = ''
                        groupFirst.forEach((item) => { // 判断这个组是否出现过，出现则在groupFirst里做个标记
                            if (item == element.fieldGroup) {
                                isHave = true
                                thisGroup = item // 记住这个组id
                            }
                        })
                        if (!isHave) { // 如果没有出现过新建一个对象放入newList里面
                            let newObj = {
                                fieldGroup: element.fieldGroup,
                                cnFieldCaption: this.returnGroupName(element.fieldGroup),
                                group: []
                            }
                            newObj.group.push(element)
                            newLList.push(newObj)
                            groupFirst.push(element.fieldGroup)
                        } else { // 如果出现过就找之前创建的对象将自己放入到group数组中
                            newLList.forEach((them) => {
                                if (them.fieldGroup == thisGroup) {
                                    them.group.push(element)
                                }
                            })
                        }
                    } else {
                        newLList.push(element)
                    }
                })
                return newLList
            }
            let baseArr = setArrChildFunc(3210) // 公司信息fieldId ==3210
            editSet = processDataFunc(baseArr)
            this.customerArr = setArrChildFunc(3211) // 3211 客户信息
            let filingFields = []
            this.customerArr.forEach(ele => {
                let obj = JSON.parse(JSON.stringify(ele))
                filingFields.push(obj)
            })
            let customerEditSet = processDataFunc(this.customerArr)
            let filingEditSet = processDataFunc(filingFields)
            // 过滤出isEditShow ==2
            let isEditShowSet = editSet.filter(ele => ele.isEditShow == 2)
            let otherEditShowSet = editSet.filter(ele => ele.isEditShow != 2)
            this.isEditShowSet = isEditShowSet
            this.customerFields = customerEditSet.filter(ele => ele.isEditShow != 2 && ele.fieldName != 'custRelyType' && ele.fieldName != 'custId')
            let ownerFields = customerEditSet.filter(ele => ele.isEditShow != 2 && ele.fieldGroup == 31)
            this.ownerFields = Object.freeze(ownerFields)
            this.filingFields = filingEditSet.filter(ele => ele.isEditShow != 2 && ele.fieldGroup != 31)
            this.isEditShowSetcustomer = customerEditSet.filter(ele => ele.isEditShow == 2)
            this.editSet = otherEditShowSet
            // console.log(this.customerFields, '客户字段')
        }
    },
    watch: {
        $route(val, old) {
            if (val.path.indexOf('/sale/tabs/new/') != -1) {
                this.setMenu()
            }
        }
    },
    components: {
        'baseMsg': baseMsg,
        'contact': contact,
        'goods-list': goodsList,
        'business-card': BusinessCard
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
